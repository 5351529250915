import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchPurchaseQuote } from '../../../redux/purchase-quote';

interface CurrentPosition {
  latitude: number;
  longitude: number;
}

interface PromoCodeFormProps {
  currentPosition: CurrentPosition | null;
}

export default function PromoCodeForm({
  currentPosition,
}: PromoCodeFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const purchaseQuote = useSelector((state: RootState) => state.purchaseQuote.data);
  const cartId = localStorage.getItem('cart_id');

  const {
    register, formState: { errors }, clearErrors, setError, setValue, handleSubmit,
  } = useForm({
    mode: 'onChange',
  });

  const updatePromoCode = async (promoCode?: string) => {
    const currentPayload = {
      deliveryLat: currentPosition?.latitude,
      deliveryLon: currentPosition?.longitude,
      promoCode,
      cartId,
      deliveryProviderId: purchaseQuote?.delivery?.delivery_provider_id,
    };
    const response = await dispatch(fetchPurchaseQuote({ ...currentPayload }));
    if (response?.type === 'purchase/fetchPurchaseQuote/rejected') {
      const message = response.payload.error_message;
      setError('promo_code', { message, type: 'manual' });
    } else {
      clearErrors();
    }
  };

  const applyPromoCode = (data: any) => {
    const promoCode = data.promo_code;
    if (promoCode) {
      updatePromoCode(promoCode);
    }
  };

  const removePromoCode = () => {
    setValue('promo_code', '');
    updatePromoCode();
  };
  return (
    <div className='flex flex-col space-y-3'>

      <div>
        <label htmlFor='promoCode' className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'>Promo code</label>
        <div className='relative'>
          <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-5 h-5 text-gray-500 dark:text-gray-400'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m8.99 14.993 6-6m6 3.001c0 1.268-.63 2.39-1.593 3.069a3.746 3.746 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043 3.745 3.745 0 0 1-3.068 1.593c-1.268 0-2.39-.63-3.068-1.593a3.745 3.745 0 0 1-3.296-1.043 3.746 3.746 0 0 1-1.043-3.297 3.746 3.746 0 0 1-1.593-3.068c0-1.268.63-2.39 1.593-3.068a3.746 3.746 0 0 1 1.043-3.297 3.745 3.745 0 0 1 3.296-1.042 3.745 3.745 0 0 1 3.068-1.594c1.268 0 2.39.63 3.068 1.593a3.745 3.745 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.297 3.746 3.746 0 0 1 1.593 3.068ZM9.74 9.743h.008v.007H9.74v-.007Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z'
              />
            </svg>
          </div>
          <input
            type='text'
            {...register('promo_code', { required: t('Promo code is required') })}
            disabled={purchaseQuote?.promotion !== null}
            id='promoCode'
            className={`block w-full p-4 ps-10 text-sm text-black placeholder:text-gray-400 border rounded-lg bg-white focus:ring-primary focus:border-primary disabled:bg-zinc-100 ${
              errors.promo_code ? 'border-red-500' : 'border-gray-300'
            }`}
            placeholder={t('checkout.orderSummary.promoCode')}
          />
          {purchaseQuote?.promotion
            ? (
              <button
                type='button'
                onClick={removePromoCode}
                className='text-white absolute end-2.5 bottom-2.5 bg-red-500 hover:bg-red-600 font-medium rounded-lg text-sm px-4 py-2'
              >
                {t('checkout.orderSummary.removePromoCode')}
              </button>
            )
            : (
              <button
                type='button'
                onClick={handleSubmit(applyPromoCode)}
                className='text-white absolute end-2.5 bottom-2.5 bg-primary hover:bg-lime-600 font-medium rounded-lg text-sm px-4 py-2'
              >
                {t('checkout.orderSummary.applyPromoCode')}
              </button>
            )}
        </div>
        {errors.promo_code && <p className='text-red-500 text-xs mt-2'>{errors.promo_code.message as string}</p>}
      </div>
    </div>
  );
}
