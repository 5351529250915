import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';

type Option = {
  id: number;
  label: string;
  iconUrl: string;
  value: string;
}

interface RadioFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  errors?: object;
  control: any;
  options: Option[];
}

const RadioField = forwardRef<HTMLInputElement, RadioFieldProps>(({
  name, control, errors = {}, options,
}, ref) => (
  <div className='mb-5'>
    <div className='mt-2'>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <ul className='grid w-full gap-6 md:grid-cols-2'>
            {options.map((option: Option) => (
              <li key={option.id}>
                <input
                  ref={ref}
                  type='radio'
                  id={`${name}-${option.id}`}
                  name={name}
                  value={option.value}
                  className='hidden peer'
                  onChange={onChange}
                  onBlur={onBlur}
                  checked={value === option.value}
                />
                <label
                  htmlFor={`${name}-${option.id}`}
                  className={classNames(
                    'inline-flex items-center w-full p-3 bg-white rounded-md border-0 text-black shadow-sm ring-1 ring-inset ring-gray-300 cursor-pointer',
                    { 'ring-primary': option.value === value },
                  )}
                >
                  <img src={option.iconUrl} alt={option.label} className='h-10 w-10' />
                  <span className='ms-2 text-sm font-medium leading-6 text-black mb-0'>{option.label}</span>
                </label>
              </li>
            ))}
          </ul>
        )}
      />
    </div>
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <p className='text-red-500 text-xs mt-2'>{message}</p>}
    />
  </div>
));

export default RadioField;
