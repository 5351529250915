import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Purchase } from '../types/purchase';
import Api from '../services';

interface PurchaseState {
  data: Purchase | undefined;
  error: string | null;
}

const initialState: PurchaseState = {
  data: undefined,
  error: null,
};

interface PurchaseQuote {
  promoCode?: string | null;
  deliveryProviderId?: number | null;
  deliveryLat?: number;
  deliveryLon?: number;
  cartId?: string | null;
}

export const fetchPurchaseQuote = createAsyncThunk(
  'purchase/fetchPurchaseQuote',
  async (data: PurchaseQuote, { rejectWithValue }) => {
    try {
      const payload = {
        delivery_lat: data.deliveryLat,
        delivery_lon: data.deliveryLon,
        cart_id: data.cartId,
        ...(data.deliveryProviderId && { delivery_provider_id: data.deliveryProviderId }),
        ...(data.promoCode && { promo_code: data.promoCode }),
      };
      const response = await Api.purchases.purchaseQuote(payload);
      return response;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error?.response?.data);
      }
    }
  },
);

const purchaseQuoteSlice = createSlice({
  name: 'purchaseQuote',
  initialState,
  reducers: {
    resetPurchaseQuote: (state) => {
      state.data = undefined;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseQuote.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchPurchaseQuote.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchPurchaseQuote.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export const { resetPurchaseQuote } = purchaseQuoteSlice.actions;

export default purchaseQuoteSlice.reducer;
