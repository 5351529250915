import { useEffect } from 'react';
import { GoSell } from '@tap-payments/gosell';
import { useTranslation } from 'react-i18next';
import EmptyPlaceholder from '../../../components/empty-placholder';
import emptyCardsPlaceholder from '../../../assets/images/empty-cards.svg';
import usePaymentMethods from '../../../hooks/use-payment-methods';
import PaymentMethodCard from './components/payment-method-card';

export default function PaymentMethods() {
  const {
    fetchPaymentMethods, loading, paymentMethods, fetchTapConfigs, tapPublicKey, tapUserDetails, deletePaymentMethod, expireCache,
  } = usePaymentMethods();

  const { t } = useTranslation();

  useEffect(() => {
    fetchPaymentMethods();
    fetchTapConfigs();
  }, []);

  const callbackFunc = (response: any) => {
    if (response) {
      fetchPaymentMethods();
    }
  };

  return (
    <>
      <div className='space-y-6 sm:px-6 lg:col-span-9 lg:px-0'>
        <section>
          <div className='shadow sm:rounded-md'>
            <div className='bg-white px-4 py-6 sm:p-6 rounded-md'>
              <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between'>
                <div>
                  <h2 className='text-lg font-medium leading-6 text-black'>{t('account.paymentMethods.title')}</h2>
                  <p className='mt-1 text-sm text-secondary'>
                    {t('account.paymentMethods.subtitle')}
                  </p>
                </div>
                <button
                  type='button'
                  className='mt-2 sm:mt-0 self-start sm:self-center w-auto rounded-md bg-primary px-3 py-2 text-sm font-medium leading-6 text-white shadow-sm hover:bg-lime-600 disabled:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600'
                  onClick={() => {
                    expireCache();
                    GoSell.openLightBox();
                  }}
                >
                  {t('account.paymentMethods.addCard')}
                </button>
              </div>
              <div className='mt-4'>
                {loading ? (
                  <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4'>
                    {[...Array(2)].map((item) => (
                      <div key={item} className='w-full h-20 bg-gray-300 rounded-md animate-pulse' />
                    ))}
                  </div>
                ) : paymentMethods?.length === 0 ? (
                  <div className='flex justify-center w-full'>
                    <EmptyPlaceholder
                      title={t('account.paymentMethods.savedCards.noCardsMessage')}
                      subtitle={t('account.paymentMethods.addCard')}
                      imageSrc={emptyCardsPlaceholder}
                    />
                  </div>
                ) : (
                  <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4'>
                    {paymentMethods.map((paymentMethod) => (
                      <PaymentMethodCard
                        key={paymentMethod.id}
                        data={paymentMethod}
                        onDelete={deletePaymentMethod}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <GoSell
        gateway={{
          publicKey: tapPublicKey,
          language: 'en',
          supportedCurrencies: ['SAR'],
          supportedPaymentMethods: 'all',
          callback: callbackFunc,
        }}
        customer={tapUserDetails}
        transaction={{
          mode: 'save_card',
        }}
      />
    </>
  );
}
