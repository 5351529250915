import { useDispatch } from 'react-redux';
import VerificationInput from 'react-verification-input';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import clevertap from 'clevertap-web-sdk';
import { useTranslation } from 'react-i18next';
import { login } from '../../../redux/auth';
import { AppDispatch } from '../../../redux/store';
import Api from '../../../services';
import { getProfile } from '../../../redux/profile';
import Alert from '../../../components/alert';

export default function VerifyIdentity() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { locale } = useParams();
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(180);
  const [username, setUsername] = useState(location.state.username);
  const [data, setData] = useState(location.state.data);
  const [type, setType] = useState(location.state.type);
  const [error, setError] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const verifyOtp = (code: string) => {
    const chaizerDeviceId = localStorage.getItem('chaizer_device_id');
    dispatch(login({
      grant_type: 'password',
      client_id: 'chaizer-webstore',
      client_secret: 'chaizer',
      username: location.state.username,
      password: code,
      chaizer_device_id: chaizerDeviceId !== null ? +chaizerDeviceId : undefined,
    })).then((response: any) => {
      const from = location.state?.from?.pathname || `/${locale}`;
      if (response?.type === 'auth/login/fulfilled') {
        dispatch(getProfile()).then(async (response) => {
          if (response?.type === 'profile/getProfile/fulfilled') {
            clevertap.onUserLogin.push({
              Site: {
                Identity: response.payload?.id,
              },
            });
            navigate(from);
          }
        });
      } else if (response?.payload?.error === 'invalid_grant') {
        setError(t('shared.otpError'));
      }
    });
  };

  const resendOtp = (username: string) => {
    Api.accounts
      .sendOtp({ username })
      .then((response) => {
        navigate('.', { replace: true, state: { username: data.email, data: response.data, type: type === 'email' ? 'mobile' : 'email' } });
        setUsername(username);
        setData(response.data);
        setType(type === 'email' ? 'mobile' : 'email');
        setTimeLeft(180);
        setIsAlertVisible(true);

        setTimeout(() => {
          setIsAlertVisible(false);
        }, 5000);
      })
      .catch((error) => {
        setError(error?.response?.data?.error_message);
      });
  };

  useEffect(() => {
    if (!timeLeft) return undefined;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  return (
    <>
      <p className='text-center mt-3 mb-10 text-base font-medium text-secondary'>
        {t('auth.verification.subtitle')}
        {' '}
        <span className='text-primary' dir='ltr'>{username}</span>
      </p>
      <div className='flex flex-row items-center justify-center' dir='ltr'>
        <VerificationInput
          classNames={{
            container: 'w-full',
            character: 'w-full h-11/12 flex flex-col items-center justify-center text-center px-5 rounded-xl border border-gray-200 text-lg bg-white',
            characterInactive: 'text-secondary',
            characterSelected: 'focus:bg-gray-50 focus:ring-1 focus:ring-inset focus:ring-primary outline-primary text-primary',
            characterFilled: 'text-primary',
          }}
          autoFocus
          onComplete={verifyOtp}
        />
      </div>
      {error && <p className='text-red-500 text-xs mt-2'>{error}</p>}
      {timeLeft > 0 ? (
        <p className='mt-6 text-center font-semibold leading-6 text-secondary'>
          {t('auth.verification.requestOtp')}
          {' '}
          <span className='text-primary'>
            {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
          </span>
        </p>
      ) : (
        <button
          type='button'
          className='mt-6 mx-auto text-center flex justify-center font-semibold leading-6 text-primary cursor-pointer'
          onClick={() => resendOtp(location.state.username)}
        >
          {t('auth.verification.resendOtp')}
        </button>
      )}
      {type === 'mobile'
        && (
          <>
            {data?.is_registered && (data?.email || data?.unverified_email)
              && (
                <button
                  type='button'
                  className='mt-6 mx-auto text-center flex justify-center font-semibold leading-6 text-primary cursor-pointer'
                  onClick={() => resendOtp(data?.email || data?.unverified_email)}
                >
                  {t('auth.verification.emailOtp')}
                </button>
              )}
            {!data?.is_registered && (
              <button
                type='button'
                className='mt-6 mx-auto text-center flex justify-center font-semibold leading-6 text-primary cursor-pointer'
              >
                {t('auth.verification.emailLogin')}
              </button>
            )}
          </>
        )}
      {type === 'email'
        && (
          <>
            {data?.is_registered && (data?.phone_number)
              && (
                <button
                  type='button'
                  className='mt-6 mx-auto text-center flex justify-center font-semibold leading-6 text-primary cursor-pointer'
                  onClick={() => resendOtp(data?.phone_number)}
                >
                  {t('auth.verification.phoneOtp')}
                </button>
              )}
            {!data?.is_registered && (
              <button
                type='button'
                className='mt-6 mx-auto text-center flex justify-center font-semibold leading-6 text-primary cursor-pointer'
              >
                {t('auth.verification.phoneLogin')}
              </button>
            )}
          </>
        )}
      <Alert
        title={t('auth.verification.successAlertTitle')}
        text={t('auth.verification.successAlertSubtitle')}
        isAlertVisible={isAlertVisible}
        onClose={() => setIsAlertVisible(false)}
        type='success'
      />
    </>
  );
}
