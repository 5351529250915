import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

export default function PaymentBreakdown() {
  const { t } = useTranslation();
  const purchaseQuote = useSelector((state: RootState) => state.purchaseQuote.data);
  return (
    <dl className='space-y-6 text-sm font-medium text-secondary'>
      <div className='flex justify-between'>
        <dt>
          {' '}
          {t('checkout.orderSummary.subtotal')}
        </dt>
        <dd className='text-black'>
          {purchaseQuote?.subtotal?.amount.toFixed(2) || 0}
          {' '}
          {t('shared.currency')}
        </dd>
      </div>
      {(purchaseQuote?.delivery?.subtotal?.amount ?? 0) > 0 && (
        <div className='flex justify-between'>
          <dt>
            {' '}
            {t('checkout.orderSummary.deliveryFees')}
          </dt>
          <dd className='text-black'>
            {purchaseQuote?.delivery?.subtotal?.amount.toFixed(2) || 0}
            {' '}
            {t('shared.currency')}
          </dd>
        </div>
      )}
      {purchaseQuote?.promotion?.code && (
        <div className='flex justify-between'>
          <dt className='flex'>
            {t('checkout.orderSummary.discount')}
            <span className='ml-2 rtl:mr-2 rounded-full bg-primary px-2 py-0.5 text-xs tracking-wide text-white'>
              {purchaseQuote?.promotion.code}
            </span>
          </dt>
          <dd className='text-black'>
            -
            {purchaseQuote?.discount?.amount.toFixed(2) || 0}
            {' '}
            {t('shared.currency')}
          </dd>
        </div>
      )}
      <div className='flex justify-between'>
        <dt>
          {t('checkout.orderSummary.vat', { vat: purchaseQuote?.vat_percentage })}
        </dt>
        <dd className='text-black'>
          {purchaseQuote?.vat?.amount.toFixed(2) || 0}
          {' '}
          {t('shared.currency')}
        </dd>
      </div>
      {(purchaseQuote?.wallet_balance?.amount ?? 0) > 0 && (
        <div className='flex justify-between'>
          <dt>
            {t('checkout.orderSummary.walletBalance')}
          </dt>
          <dd className='text-black'>
            -
            {purchaseQuote?.wallet_balance?.amount.toFixed(2) || 0}
            {' '}
            {t('shared.currency')}
          </dd>
        </div>
      )}
      <div className='flex justify-between border-t border-gray-200 pt-2'>
        {t('checkout.orderSummary.total')}
        <dd className='text-black'>
          {purchaseQuote?.total_required.amount.toFixed(2) || 0}
          {' '}
          {t('shared.currency')}
        </dd>
      </div>
    </dl>
  );
}
