import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import clevertap from 'clevertap-web-sdk';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from '../../../components/input';
import DateField from '../../../components/date-input';
import RadioField from '../../../components/radio-input';
import male from '../../../assets/images/male.svg';
import female from '../../../assets/images/female.svg';
import { AppDispatch, RootState } from '../../../redux/store';
import { getProfile } from '../../../redux/profile';
import Api from '../../../services';
import Alert from '../../../components/alert';
import Spinner from '../../../components/spinner';
import schema from './schema';

type FormData = {
  full_name: string;
  gender: string;
  date_of_birth: any;
}

export default function UpdateAccount() {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state: RootState) => state.profile.data);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const {
    register, handleSubmit, control, formState: { errors }, reset,
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const options = [
    {
      id: 1,
      label: t('shared.male'),
      iconUrl: male,
      value: 'male',
    },
    {
      id: 2,
      label: t('shared.female'),
      iconUrl: female,
      value: 'female',
    },
  ];

  useEffect(() => {
    if (!profile) return;

    reset({
      full_name: profile?.full_name,
      gender: profile?.gender,
      date_of_birth: { startDate: profile?.date_of_birth, endDate: profile?.date_of_birth },
    });
  }, [profile, reset]);

  const updateProfilePicture = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const formData = new FormData();
    if (event.target.files && event.target.files[0]) {
      formData.append('profile_picture', event.target.files[0]);
      Api.accounts.updateProfilePicture(formData).then(() => dispatch(getProfile()));
    }
  };

  const onSubmit = (data: FormData) => {
    const newData = { ...data, date_of_birth: data?.date_of_birth.startDate };
    setLoading(true);
    Api.accounts.updateProfile(newData).then(() => {
      clevertap.event.push('Profile updated');
      dispatch(getProfile());
      setLoading(false);
      setIsAlertVisible(true);
      setTimeout(() => {
        setIsAlertVisible(false);
      }, 5000);
    }).catch(() => {
      setLoading(false);
    });
  };
  return (
    <>
      <div className='space-y-6 sm:px-6 lg:col-span-9 lg:px-0'>
        <section>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='shadow sm:rounded-md'>
              <div className='bg-white px-4 py-6 sm:p-6 rounded-t-md'>
                <div>
                  <h2 className='text-lg font-medium leading-6 text-black'>
                    {t('account.update.title')}
                  </h2>
                  <p className='mt-1 text-sm text-secondary'>
                    {t('account.update.subtitle')}
                  </p>
                </div>
                <div className='col-span-full flex items-center gap-x-4 mt-6'>
                  {profile?.profile_picture_url
                    ? (
                      <img
                        src={profile?.profile_picture_url}
                        alt='user'
                        className='h-24 w-24 flex-none rounded-lg object-cover'
                      />
                    )
                    : <UserCircleIcon className='h-24 w-24 flex-none rounded-lg text-black object-cover' />}
                  <div>
                    <label
                      htmlFor='file-upload'
                      className='rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm cursor-pointer'
                    >
                      <span>
                        {t('account.update.changePicture')}
                      </span>
                      <input id='file-upload' name='file-upload' type='file' className='sr-only' onChange={updateProfilePicture} />
                    </label>
                    <p className='mt-3 text-xs leading-5 text-gray-400'>{t('account.update.changePictureHint')}</p>
                  </div>
                </div>
                <div className='mt-6 lg:w-3/5 w-full'>
                  <InputField
                    label={t('shared.fullName')}
                    type='text'
                    errors={errors}
                    {...register('full_name')}
                  />
                  <DateField
                    label={t('shared.dob')}
                    name='date_of_birth'
                    control={control}
                  />
                  <RadioField
                    control={control}
                    options={options}
                    {...register('gender')}
                  />
                </div>
              </div>
              <div className='bg-gray-50 p-3 text-left rounded-b-md'>
                <button
                  type='submit'
                  className='inline-flex justify-center rounded-md bg-primary px-3 py-2 text-base font-medium leading-6 text-white shadow-sm hover:bg-lime-600 disabled:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600'
                  disabled={loading}
                >
                  {loading ? <Spinner /> : t('shared.save')}
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
      <Alert
        title={t('account.update.successAlertTitle')}
        text={t('account.update.successAlertSubtitle')}
        isAlertVisible={isAlertVisible}
        onClose={() => setIsAlertVisible(false)}
        type='success'
      />
    </>
  );
}
