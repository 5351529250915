import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import Api from '../../../services';
import successAnimation from '../../../assets/animations/success.json';
import failureAnimation from '../../../assets/animations/failed.json';

export default function CheckoutDetails() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [purchaseDetails, setPurchaseDetails] = useState<any>(null);

  useEffect(() => {
    if (!id) return;

    Api.purchases.getPurchaseDetails(id).then((response) => setPurchaseDetails(response));
  }, [id]);
  return (
    <main className='flex flex-col items-center justify-center gap-4 px-4 py-20'>
      <div className='flex flex-col items-center gap-2 text-center'>
        {purchaseDetails?.status === 'successful'
          ? <Lottie animationData={successAnimation} loop className='h-40 w-40' />
          : <Lottie animationData={failureAnimation} loop className='h-40 w-40' />}
        <span className='inline-flex items-center rounded-md bg-primary px-2 py-1 text-xs font-medium text-white my-2'>
          {t('purchaseStatus.orderNumber')}
          {' '}
          #
          {id}
        </span>
        <h1 className='font-semibold text-3xl text-black'>
          {purchaseDetails?.status === 'successful' ? t('purchaseStatus.success.title') : t('purchaseStatus.failed.title')}
        </h1>
        <p className='max-w-[600px] text-gray-900 md:text-xl/tight dark:text-gray-400'>
          {purchaseDetails?.status === 'successful' ? t('purchaseStatus.success.subtitle') : t('purchaseStatus.failed.subtitle')}
        </p>
        <div className='mt-10 flex items-center justify-center'>
          <Link
            to='/'
            className='rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
          >
            {t('notFound.cta')}
          </Link>
        </div>
      </div>
    </main>
  );
}
