import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CartProduct } from '../../../types/cart';
import ProductCard from '../../../components/product-card';
import Spinner from '../../../components/spinner';
import { RootState } from '../../../redux/store';
import DEFAULT_IMAGE from '../../../assets/images/default-product.jpg';
import PromoCodeForm from './promo-code-form';
import PaymentBreakdown from './payment-breakdown';

interface CurrentPosition {
  latitude: number;
  longitude: number;
}
interface OrderSummaryProps {
  handleQuantityChange: (id: number, increment: boolean) => void;
  handleRemove: (id: number) => void;
  confirmOrder: () => void;
  loading: boolean;
  disableSubmit: boolean;
  currentPosition: CurrentPosition | null;
}

export default function OrderSummary({
  handleQuantityChange,
  handleRemove,
  confirmOrder,
  loading,
  disableSubmit,
  currentPosition,
}: OrderSummaryProps) {
  const { products } = useSelector((state: RootState) => state.cart);
  const { t } = useTranslation();

  return (
    <div className='lg:order-2'>
      <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.orderSummary.title')}</h2>
      <div className='mt-4 rounded-lg border border-gray-200 bg-white shadow-sm'>
        <h3 className='sr-only'>Items in your cart</h3>
        <ul className='divide-y divide-gray-200'>
          {products?.map((product: CartProduct) => (
            <div key={product.id} className='px-4 py-2 sm:px-6'>
              <ProductCard
                data={product}
                logo={product.logo?.url || DEFAULT_IMAGE}
                handleIncrement={() => handleQuantityChange(product.id, true)}
                handleDecrement={() => handleQuantityChange(product.id, false)}
                handleRemove={() => handleRemove(product.id)}
              />
            </div>
          ))}
        </ul>
        <div className='border-t border-gray-200 px-4 py-6 sm:px-6 text-sm'>
          <PromoCodeForm currentPosition={currentPosition} />
        </div>
        <dl className='border-t border-gray-200 px-4 py-6 sm:px-6 text-sm'>
          <PaymentBreakdown />
        </dl>
        <div className='border-t border-gray-200 px-4 py-6 sm:px-6'>
          <button
            type='button'
            className='w-full rounded-md bg-primary px-3 py-2 text-base font-medium leading-6 text-white shadow-sm hover:bg-lime-600 disabled:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600'
            onClick={confirmOrder}
            disabled={disableSubmit}
          >
            {loading ? <Spinner /> : t('checkout.confirmOrder')}
          </button>
        </div>
      </div>
    </div>
  );
}
