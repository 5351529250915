import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import profile from './profile';
import cart from './cart-slice';
import purchaseQuote from './purchase-quote';

const rootReducer = combineReducers({
  auth,
  profile,
  cart,
  purchaseQuote,
});

export default rootReducer;
