import HttpHelpers from './helpers';

const TapApiEndpoints = {
  getTapSdkKey: () => HttpHelpers.authenticatedAxios
    .get('/api/v1/users/tap_public_key')
    .then((response) => response.data),
  getTapUser: () => HttpHelpers.authenticatedAxios
    .get('/api/v1/users/me/tap/payment_customer_id')
    .then((response) => response.data),
};

export default TapApiEndpoints;
