import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '../../redux/store';
import useCartActions from '../../hooks/use-cart-actions';
import Map from './components/map';
import PhoneForm from '../../views/account/credentials/phone';
import DeliveryProviders from './components/delivery-providers';
import EmptyPlaceholder from '../../components/empty-placholder';
import cartPlaceholder from '../../assets/images/empty-cart.svg';
import Api from '../../services';
import Alert from '../../components/alert';
import PaymentMethods from './components/payment-methods';
import OrderSummary from './components/order-summary';
import PaymentIframe from './components/payment-iframe';
import { fetchPurchaseQuote, resetPurchaseQuote } from '../../redux/purchase-quote';
import locationPlaceholder from '../../assets/images/location-placeholder.svg';
import CheckoutSkeleton from './components/checkout-skeleton';
import FullfillmentMethods, { FulfillmentMethod } from './components/fullfillment-methods';

interface CurrentPosition {
  latitude: number;
  longitude: number;
}

export default function Checkout() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { products } = useSelector((state: RootState) => state.cart);
  const profile = useSelector((state: RootState) => state.profile.data);
  const purchaseQuote = useSelector((state: RootState) => state.purchaseQuote.data);
  const authenticated = useSelector((state: RootState) => state.auth.authenticated);
  const { handleQuantityChange: updateCartQuantity, handleRemoveProduct } = useCartActions();
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [isLocationPermissionEnabled, setIsLocationPermissionEnabled] = useState<boolean>(false);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const [currentPosition, setCurrentPosition] = useState<CurrentPosition | null>(null);
  const [quoteFetched, setQuoteFetched] = useState(false);
  const [fullfillmentMethod, setFullfillmentMethod] = useState<'delivery' | 'pickup' | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const cartId = localStorage.getItem('cart_id');

  const handleQuantityChange = async (productId: number, increment: boolean) => {
    try {
      if (increment) {
        await updateCartQuantity(productId, true);
      } else {
        await updateCartQuantity(productId, false);
      }
      await dispatch(fetchPurchaseQuote({
        deliveryLat: currentPosition?.latitude,
        deliveryLon: currentPosition?.longitude,
        deliveryProviderId: purchaseQuote?.delivery?.delivery_provider_id,
        promoCode: purchaseQuote?.promotion?.code,
        cartId,
      }));
    } catch (error) {
      console.error('Failed to update quantity:', error);
    }
  };

  const handleRemove = async (productId: number) => {
    try {
      await handleRemoveProduct(productId);
      await dispatch(fetchPurchaseQuote({
        deliveryLat: currentPosition?.latitude,
        deliveryLon: currentPosition?.longitude,
        deliveryProviderId: purchaseQuote?.delivery?.delivery_provider_id,
        promoCode: purchaseQuote?.promotion?.code,
        cartId,
      }));
    } catch (error) {
      console.error('Failed to remove item:', error);
    }
  };

  const handleFulfillmentMethodSelect = (method: FulfillmentMethod) => {
    setFullfillmentMethod(method);
    if (method === 'pickup') {
      dispatch(fetchPurchaseQuote({
        deliveryLat: currentPosition?.latitude,
        deliveryLon: currentPosition?.longitude,
        deliveryProviderId: null,
        promoCode: purchaseQuote?.promotion?.code,
        cartId,
      }));
    }
  };

  const handleCoordinatesChange = (lat: number, lng: number) => {
    setCurrentPosition({
      latitude: lat,
      longitude: lng,
    });

    if (currentPosition?.latitude !== lat || currentPosition?.longitude !== lng) {
      dispatch(fetchPurchaseQuote({
        deliveryLat: lat,
        deliveryLon: lng,
        deliveryProviderId: purchaseQuote?.delivery?.delivery_provider_id,
        promoCode: purchaseQuote?.promotion?.code,
        cartId,
      }));
    }
  };

  useEffect(() => {
    if (currentPosition && !quoteFetched) {
      dispatch(fetchPurchaseQuote({
        deliveryLat: currentPosition?.latitude,
        deliveryLon: currentPosition?.longitude,
        deliveryProviderId: purchaseQuote?.delivery?.delivery_provider_id,
        promoCode: purchaseQuote?.promotion?.code,
        cartId,
      }));
      setQuoteFetched(true);
    }
  }, [currentPosition, quoteFetched]);

  const hasPhoneNumber = profile?.phone_number;

  const handleConfirmOrder = async () => {
    setSubmitting(true);
    setIsErrorVisible(false);
    try {
      const response = await Api.purchases.purchaseCheckout({
        delivery_lat: currentPosition?.latitude,
        delivery_lon: currentPosition?.longitude,
        payment_method: 'card',
        payment_card_id: selectedCardId,
        include_delivery: true,
        ecommerce_purchase: true,
        cart_id: cartId,
        signature: purchaseQuote?.signature,
        delivery_provider_id: purchaseQuote?.delivery?.delivery_provider_id,
        promo_code: purchaseQuote?.promotion?.code,
      });
      if (!response.payment) {
        if (response.status === 'successful') {
          navigate(`/checkout/${response.id}`);
        } else {
          setIsErrorVisible(true);
        }
      } else if (response.payment.card_3ds_redirect_url) {
        setIframeUrl(response.payment.card_3ds_redirect_url);
      }
    } catch (error) {
      setIsErrorVisible(true);
    } finally {
      setSubmitting(false);
    }
  };

  const handleIframeMessage = (event: MessageEvent) => {
    if (event.data?.purchaseStatus === 'successful') {
      setIframeUrl(null);
      dispatch(resetPurchaseQuote());
      navigate(`/checkout/${event.data?.purchaseId}`);
    } else if (event.data?.purchaseStatus === 'failed') {
      setIframeUrl(null);
      navigate(`/checkout/${event.data?.purchaseId}`);
    }
  };

  useEffect(() => {
    if (iframeUrl) {
      window.addEventListener('message', handleIframeMessage);
    }
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [iframeUrl]);

  const requestLocationPermission = async () => {
    if (!navigator.geolocation) {
      setIsLocationPermissionEnabled(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        setIsLocationPermissionEnabled(true);
      },
      (error) => {
        switch (error.code) {
        case error.PERMISSION_DENIED:
          setIsLocationPermissionEnabled(false);
          break;
        case error.POSITION_UNAVAILABLE:
          setIsLocationPermissionEnabled(false);
          break;
        case error.TIMEOUT:
          setIsLocationPermissionEnabled(false);
          break;
        default:
          setIsLocationPermissionEnabled(false);
          break;
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );
  };

  useEffect(() => {
    requestLocationPermission();
  }, []);

  if (!quoteFetched) {
    return <CheckoutSkeleton />;
  }

  if (!isLocationPermissionEnabled) {
    return (
      <EmptyPlaceholder
        title={t('checkout.locationPermission.title')}
        subtitle={t('checkout.locationPermission.subtitle')}
        imageSrc={locationPlaceholder}
      />
    );
  }

  if (products?.length === 0) {
    return (
      <EmptyPlaceholder
        title={t('cart.emptyTitle')}
        subtitle={t('cart.emptySubtitle')}
        imageSrc={cartPlaceholder}
      />
    );
  }
  return (
    <div>
      <div className='mx-auto max-w-2xl px-4 pb-24 lg:px-8 lg:py-12 sm:px-6 lg:max-w-7xl lg:px-0'>
        <h2 className='sr-only'>Checkout</h2>
        <div className='lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16'>
          <div className='lg:order-1'>
            {authenticated && !hasPhoneNumber && (
              <div className='border-b border-gray-200 pb-6 mb-4'>
                <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.addMobileNumber')}</h2>
                <PhoneForm />
              </div>
            )}
            <FullfillmentMethods
              onSelect={handleFulfillmentMethodSelect}
              selectedOption={fullfillmentMethod}
              isDeliveryAvailable={(purchaseQuote?.delivery?.delivery_providers?.length ?? 0) > 0}
            />
            {fullfillmentMethod === 'delivery' && (
              <>
                <Map onCoordinatesChange={handleCoordinatesChange} />
                <DeliveryProviders currentPosition={currentPosition} />
              </>
            )}
            {purchaseQuote?.requires_payment
              && (
                <PaymentMethods
                  setSelectedCardId={setSelectedCardId}
                  selectedCardId={selectedCardId}
                />
              )}
          </div>
          <OrderSummary
            handleQuantityChange={handleQuantityChange}
            handleRemove={handleRemove}
            confirmOrder={handleConfirmOrder}
            currentPosition={currentPosition}
            loading={false}
            disableSubmit={!!(!hasPhoneNumber || !fullfillmentMethod || (fullfillmentMethod === 'delivery' && !purchaseQuote?.delivery?.delivery_provider_id) || submitting || (purchaseQuote?.requires_payment && !selectedCardId))}
          />
        </div>
      </div>
      <PaymentIframe
        iframeUrl={iframeUrl}
        setIframeUrl={setIframeUrl}
      />
      <Alert
        title={t('purchaseStatus.failed.title')}
        text={t('purchaseStatus.failed.subtitle')}
        isAlertVisible={isErrorVisible}
        onClose={() => setIsErrorVisible(false)}
        type='error'
      />
    </div>
  );
}
