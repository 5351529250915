import { useTranslation } from 'react-i18next';

export default function CheckoutSkeleton() {
  const { t } = useTranslation();
  return (
    <div>
      <div className='mx-auto max-w-2xl px-4 pb-24 lg:px-8 lg:py-12 sm:px-6 lg:max-w-7xl lg:px-0'>
        <h2 className='sr-only'>Checkout</h2>
        <div className='lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16'>
          <div className='lg:order-1'>
            <div className='border-b border-gray-200 pb-6 mb-4'>
              <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.deliveryAddress')}</h2>
              <div className='animate-pulse bg-gray-300 rounded h-96 w-full' />
            </div>
            <div className='border-b border-gray-200 pb-6 mb-4'>
              <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.deliveryProviders.title')}</h2>
              <div className='grid grid-cols-1 gap-4'>
                {[...Array(2)].map((item) => (
                  <div key={item} className='h-[58px] rounded-lg animate-pulse bg-gray-300 shadow-sm' />
                ))}
              </div>
            </div>
          </div>
          <div className='lg:order-2'>
            <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.orderSummary.title')}</h2>
            <div className='mt-4 rounded-lg'>
              <div className='animate-pulse bg-gray-300 rounded h-[575px] w-full' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
