import HttpHelpers from './helpers';

const PaymentMethodsApiEndpoints = {
  getCards: (): Promise<any> => HttpHelpers.authenticatedAxios
    .get('/api/v1/credit_cards')
    .then((response) => response.data),
  deleteCard: (cardId: string): Promise<any> => HttpHelpers.authenticatedAxios
    .delete(`/api/v1/credit_cards/${cardId}`),
  expireCache: (): Promise<any> => HttpHelpers.authenticatedAxios
    .post('/api/v1/credit_cards/expire_cache'),

};

export default PaymentMethodsApiEndpoints;
