import { useEffect } from 'react';
import { GoSell } from '@tap-payments/gosell';
import { useTranslation } from 'react-i18next';
import EmptyPlaceholder from '../../../components/empty-placholder';
import usePaymentMethods from '../../../hooks/use-payment-methods';
import emptyCardsPlaceholder from '../../../assets/images/empty-cards.svg';
import PaymentMethodCard from './payment-method-card';

interface PaymentMethodsProps {
  setSelectedCardId: (id: string | null) => void;
  selectedCardId: string | null;
}

export default function PaymentMethods({
  setSelectedCardId,
  selectedCardId,
}: PaymentMethodsProps) {
  const {
    fetchPaymentMethods,
    loading,
    paymentMethods,
    fetchTapConfigs,
    tapPublicKey,
    tapUserDetails,
    expireCache,
  } = usePaymentMethods();

  const { t } = useTranslation();

  useEffect(() => {
    fetchPaymentMethods();
    fetchTapConfigs();
  }, []);

  const callbackFunc = (response: any) => {
    if (response) {
      fetchPaymentMethods();
    }
  };

  if (loading) {
    return (
      <div className='grid grid-cols-1 gap-4'>
        {[...Array(2)].map((item) => (
          <div key={item} className='w-full h-20 bg-gray-300 rounded-md animate-pulse' />
        ))}
      </div>
    );
  }

  return (
    <div className='pb-6'>
      <div className='flex justify-between items-center mb-4'>
        <h2 className='text-lg font-medium text-black'>{t('account.paymentMethods.title')}</h2>
        <button
          type='button'
          className='rounded-md bg-primary px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-lime-600 disabled:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600'
          onClick={() => {
            expireCache();
            GoSell.openLightBox();
          }}
        >
          {t('account.paymentMethods.addCard')}
        </button>
      </div>
      {paymentMethods?.length === 0 ? (
        <EmptyPlaceholder
          title={t('account.paymentMethods.savedCards.noCardsMessage')}
          subtitle=''
          imageSrc={emptyCardsPlaceholder}
          fullHeight={false}
        />
      ) : (
        <div className='grid grid-cols-1 gap-4'>
          {paymentMethods.map((paymentMethod) => (
            <PaymentMethodCard
              key={paymentMethod.id}
              data={paymentMethod}
              onSelect={() => setSelectedCardId(paymentMethod.id)}
              selectedCardId={selectedCardId}
            />
          ))}
        </div>
      )}
      <GoSell
        gateway={{
          publicKey: tapPublicKey,
          language: 'en',
          supportedCurrencies: ['SAR'],
          supportedPaymentMethods: 'all',
          callback: callbackFunc,
        }}
        customer={tapUserDetails}
        transaction={{
          mode: 'save_card',
        }}
      />
    </div>
  );
}
