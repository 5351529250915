import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EmptyPlaceholder from '../../../components/empty-placholder';
import emptyPlaceholderIcon from '../../../assets/images/empty-data.svg';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchPurchaseQuote } from '../../../redux/purchase-quote';

interface CurrentPosition {
  latitude: number;
  longitude: number;
}
interface DeliveryProvidersProps {
  currentPosition: CurrentPosition | null;
}

function DeliveryProviders({ currentPosition }: DeliveryProvidersProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const purchaseQuote = useSelector((state: RootState) => state.purchaseQuote.data);
  const cartId = localStorage.getItem('cart_id');

  const handleProviderSelect = (providerId: number) => {
    dispatch(fetchPurchaseQuote({
      deliveryLat: currentPosition?.latitude,
      deliveryLon: currentPosition?.longitude,
      deliveryProviderId: providerId,
      promoCode: purchaseQuote?.promotion?.code,
      cartId,
    }));
  };

  if (purchaseQuote?.delivery?.delivery_providers?.length === 0) {
    return (
      <div className='border-b border-gray-200 pb-6 mb-4'>
        <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.deliveryProviders.title')}</h2>
        <EmptyPlaceholder
          title={t('checkout.deliveryProviders.unavailable.title')}
          subtitle={t('checkout.deliveryProviders.unavailable.message')}
          imageSrc={emptyPlaceholderIcon}
          fullHeight={false}
        />
      </div>

    );
  }
  return (
    <div className='border-b border-gray-200 pb-6 mb-4'>
      <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.deliveryProviders.title')}</h2>
      <div className='grid grid-cols-1 gap-4'>
        {purchaseQuote?.delivery?.delivery_providers?.map((provider) => (
          <div
            key={provider.id}
            className={classNames(
              'p-4 rounded-lg border border-gray-200 bg-white shadow-sm',
              { 'border-primary border-2': purchaseQuote.delivery?.delivery_provider_id === provider.id },
            )}
            onClick={() => handleProviderSelect(provider.id)}
            onKeyDown={(e) => e.key === 'Enter' && handleProviderSelect(provider.id)}
            role='button'
            tabIndex={0}
          >
            <div className='flex items-center'>

              <p className='text-md font-medium text-black flex-1'>{provider.name}</p>
              {purchaseQuote.delivery?.delivery_provider_id === provider.id ? (
                <CheckIcon aria-hidden='true' className='h-6 w-6 text-primary' />
              ) : (
                <span className='text-sm text-gray-500'>{t('checkout.deliveryProviders.selectProvider')}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DeliveryProviders;
