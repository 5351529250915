import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface RequireAuthProps {
  children: ReactElement | null;
}

function RequireAuth({ children }: RequireAuthProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { locale } = useParams();
  const authenticated = useSelector((state: RootState) => state.auth.authenticated);

  useEffect(() => {
    if (!authenticated) {
      const loginPath = `/${locale}/auth/login`;
      if (location.pathname !== loginPath) {
        navigate(loginPath, { state: { from: location }, replace: true });
      }
    }
  }, [authenticated, navigate, location, locale]);

  if (!authenticated) {
    return null;
  }

  return children;
}

export default RequireAuth;
