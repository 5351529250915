import { CheckIcon, FireIcon, StarIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import useCart from '../../../hooks/use-cart';

interface ProductInfoProps {
  data: any;
  handleDecrement: () => void;
  handleIncrement: () => void;
  quantity: number;
  reviews: any;
}

export default function ProductInfo({
  data, handleDecrement, handleIncrement, quantity, reviews,
}: ProductInfoProps) {
  const { t } = useTranslation();
  const { addItemToCart } = useCart();
  const createMarkup = (html: string) => ({ __html: DOMPurify.sanitize(html) });

  const handleAddToCart = () => {
    addItemToCart(data.id, quantity);
  };

  return (
    <div className='mt-10 sm:mt-16 sm:px-0 lg:mt-0 lg:order-1 rtl:lg:order-2'>
      <h1 className='text-2xl font-bold tracking-tight text-black'>{data.name}</h1>
      <div className='flex flex-col sm:flex-row sm:items-center mt-3'>
        <div className='flex items-center gap-2'>
          <p className='text-md font-bold tracking-tight text-red-600 line-through'>{data.marketingPrice}</p>
          <p className='text-md font-bold tracking-tight text-secondary'>
            {data.price}
            {' '}
            {t('shared.currency')}
          </p>
        </div>
        <div className='sm:ml-4 sm:rtl:mr-4 sm:border-l sm:rtl:border-r sm:rtl:border-l-0 sm:border-gray-300 sm:pl-4 sm:rtl:pr-4 mt-2 sm:mt-0'>
          <h2 className='sr-only'>Reviews</h2>
          <div className='flex items-center'>
            <div>
              <div className='flex items-center' dir='ltr'>
                {[0, 1, 2, 3, 4].map((rating) => (
                  <StarIcon
                    key={rating}
                    aria-hidden='true'
                    className={classNames(
                      reviews.average > rating ? 'text-primary' : 'text-gray-300',
                      'h-5 w-5 flex-shrink-0',
                    )}
                  />
                ))}
              </div>
              <p className='sr-only'>
                {reviews.average}
                {' '}
                out of 5 stars
              </p>
            </div>
            <p className='ml-2 rtl:mr-2 text-sm text-secondary'>
              {t('productDetails.reviews.reviewsCount', { count: reviews.totalCount })}
            </p>
          </div>
        </div>
      </div>
      <div className='mt-6'>
        <h3 className='sr-only'>Description</h3>
        <div className='space-y-6 text-base text-secondary' dangerouslySetInnerHTML={createMarkup(data.shortDescription)} />
      </div>
      <div className='flex items-center justify-between'>
        {data.inStock
          ? (
            <div className='mt-6 flex items-center'>
              <CheckIcon aria-hidden='true' className='h-5 w-5 flex-shrink-0 text-primary' />
              <p className='ml-2 rtl:mr-2 text-sm text-secondary'>{t('productDetails.availability.inStock')}</p>
            </div>
          )
          : (
            <div className='mt-6 flex items-center'>
              <XMarkIcon aria-hidden='true' className='h-5 w-5 flex-shrink-0 text-red-500' />
              <p className='ml-2 rtl:mr-2 text-sm text-secondary'>{t('productDetails.availability.outOfStock')}</p>
            </div>
          )}
        <div className='mt-6 flex items-center'>
          <FireIcon aria-hidden='true' className='h-5 w-5 flex-shrink-0 text-primary' />
          <p className='ml-2 rtl:mr-2 text-sm text-secondary'>{t('productDetails.boughtTimes', { count: data.purchasesCount })}</p>
        </div>
      </div>
      {data.inStock
        && (
          <div className='mt-7 flex items-center justify-between gap-4'>

            <button
              type='button'
              onClick={handleAddToCart}
              className='flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-primary px-8 py-3 h-11 text-base font-medium text-white shadow-sm hover:bg-lime-600 disabled:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600 sm:w-full'
            >
              {t('cart.addToCart')}
            </button>

            <div className='flex items-center'>
              <p className='text-secondary'>
                {t('productDetails.productInfo.quantity')}
              </p>
              <button
                type='button'
                onClick={handleDecrement}
                className='px-2 border rounded text-secondary hover:bg-primary hover:text-white ltr:ml-2 rtl:mr-2'
              >
                -
              </button>
              <span className='px-2'>{quantity}</span>
              <button
                type='button'
                onClick={handleIncrement}
                className='px-2 border rounded text-secondary hover:bg-primary hover:text-white'
              >
                +
              </button>
            </div>
          </div>
        )}
      <div className='mt-8 border-t border-gray-200 pt-8'>
        <h3 className='text-md font-medium text-black'>{t('productDetails.productInfo.features')}</h3>
        <div className='prose prose-sm mt-3 text-secondary' dangerouslySetInnerHTML={createMarkup(data.description)} />
      </div>
    </div>
  );
}
