import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import Api from '../../services';
import pb from '../../assets/images/pb.svg';
import invalidQrCode from '../../assets/images/invalid-qr.svg';
import confettiAnimation from '../../assets/animations/confetti.json';

const ACTIVE_STATE_SUCCESS = 'success';
const ACTIVE_STATE_NOT_FOUND = 'pb_not_found';
const ACTIVE_STATE_ACTIVE_ORDER = 'pb_in_active_order';
const ACTIVE_STATE_ACTIVATED = 'pb_already_activated';
const ACTIVE_STATE_CANT_ACTIVATE = 'pb_cannot_be_activated';

export default function ItemActivation() {
  const params = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [activeState, setActiveState] = useState<string | null>(null);

  useEffect(() => {
    if (!params?.qrCode) return;

    setLoading(true);
    Api.items.activateItem({ qr_code: params.qrCode })
      .then(() => {
        setLoading(false);
        setActiveState(ACTIVE_STATE_SUCCESS);
      })
      .catch((e: any) => {
        setLoading(false);
        setActiveState(e.response.data.error_code);
      });
  }, [params?.qrCode]);

  const renderSuccess = () => (
    <div className='flex h-[calc(100vh-4rem)] flex-col items-center justify-center'>
      <h2 className='text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>{t('activation.success.title')}</h2>
      <p className='text-center mt-3 mb-10 text-base font-medium text-secondary'>{t('activation.success.subtitle')}</p>
      <div className='relative'>
        <Lottie animationData={confettiAnimation} loop className='h-full w-full' />
        <img src={pb} alt='chaizer' className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
      </div>
    </div>
  );

  const renderNotFound = () => (
    <div className='flex h-[calc(100vh-4rem)] flex-col items-center justify-center'>
      <h2 className='text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>{t('activation.notFound.title')}</h2>
      <p className='text-center mt-3 mb-10 text-base font-medium text-secondary'>{t('activation.notFound.subtitle')}</p>
      <img src={invalidQrCode} alt='chaizer' />
    </div>
  );

  const renderActiveOrder = () => (
    <div className='flex h-[calc(100vh-4rem)] flex-col items-center justify-center'>
      <h2 className='text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>{t('activation.activeOrder.title')}</h2>
      <p className='text-center mt-3 mb-10 text-base font-medium text-secondary'>{t('activation.activeOrder.subtitle')}</p>
      <img src={pb} alt='chaizer' />
    </div>
  );

  const renderActivatedPb = () => (
    <div className='flex h-[calc(100vh-4rem)] flex-col items-center justify-center'>
      <h2 className='text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>{t('activation.alreadyActivated.title')}</h2>
      <p className='text-center mt-3 mb-10 text-base font-medium text-secondary'>{t('activation.alreadyActivated.subtitle')}</p>
      <img src={pb} alt='chaizer' />
    </div>
  );

  const renderActivationError = () => (
    <div className='flex h-[calc(100vh-4rem)] flex-col items-center justify-center'>
      <h2 className='text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>{t('activation.cantActivate.title')}</h2>
      <p className='text-center mt-3 mb-10 text-base font-medium text-secondary'>{t('activation.cantActivate.subtitle')}</p>
      <img src={pb} alt='chaizer' />
    </div>
  );

  const renderLoader = () => (
    <div className='flex h-[calc(100vh-4rem)] items-center justify-center'>
      <svg
        className='animate-spin h-20 w-20 text-primary'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
      >
        <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4' />
        <path
          className='opacity-75'
          fill='currentColor'
          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
        />
      </svg>
    </div>
  );

  const renderContent = () => {
    switch (activeState) {
    case ACTIVE_STATE_SUCCESS:
      return renderSuccess();
    case ACTIVE_STATE_NOT_FOUND:
      return renderNotFound();
    case ACTIVE_STATE_ACTIVE_ORDER:
      return renderActiveOrder();
    case ACTIVE_STATE_ACTIVATED:
      return renderActivatedPb();
    case ACTIVE_STATE_CANT_ACTIVATE:
      return renderActivationError();
    default:
      return renderLoader();
    }
  };

  if (loading) {
    return renderLoader();
  }

  return renderContent();
}
