import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../services';
import { LoginFormData, LogoutFormData } from '../types/oidc';
import { fetchCart } from './cart-slice';

export const login = createAsyncThunk('auth/login', async (data: LoginFormData, { rejectWithValue }) => {
  try {
    const response = await Api.oidc.login(data);
    await localStorage.setItem('access_token', response?.data?.access_token);
    await localStorage.setItem('refresh_token', response?.data?.refresh_token);
    await localStorage.setItem('chaizer_device_id', response?.data?.chaizer_device_id.toString());

    const cartId = localStorage.getItem('cart_id');
    if (cartId) {
      try {
        const bindResponse = await Api.cart.bindCartToUser(cartId);

        const newCartId = bindResponse?.data?.attributes?.id;
        if (newCartId) {
          localStorage.setItem('cart_id', newCartId);
        }
      } catch (cartError) { /* empty */ }
    }
    return response;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
});

export const logout = createAsyncThunk('auth/logout', async (data: LogoutFormData, { rejectWithValue, dispatch }) => {
  try {
    const response = await Api.oidc.logout(data);
    await localStorage.removeItem('access_token');
    await localStorage.removeItem('refresh_token');
    await localStorage.removeItem('cart_id');
    const newCartId = crypto.randomUUID();
    await localStorage.setItem('cart_id', newCartId);
    dispatch(fetchCart(newCartId));

    return response;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authenticated: localStorage.getItem('access_token') !== null,
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Login by username
    builder
      .addCase(login.pending, (state) => {
        state.authenticated = false;
      });
    builder
      .addCase(login.fulfilled, (state) => {
        state.authenticated = true;
      });
    builder
      .addCase(login.rejected, (state) => {
        state.authenticated = false;
      });
    // Logout
    builder
      .addCase(logout.pending, (state) => {
        state.authenticated = false;
      });
    builder
      .addCase(logout.fulfilled, (state) => {
        state.authenticated = false;
      });
    builder
      .addCase(logout.rejected, (state) => {
        state.authenticated = false;
      });
  },
});

export const {
  setAuthenticated,
} = authSlice.actions;

export default authSlice.reducer;
