import { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import AddReviewModal from './components/add-review';
import ProductMedia from './components/media';
import ProductInfo from './components/info';
import FAQs from './components/faqs';
import Delivery from './components/delivery';
import ReviewsStatistics from './components/reviews-stats';
import ProductReviews from './components/reviews';
import ProductDetailsSkeleton from './components/product-details-skeleton';
import RetailProductsApiEndpoints from '../../services/retail_products.api';

const reviews = {
  average: 4,
  totalCount: 1624,
  featured: [
    {
      id: 1,
      rating: 5,
      content: 'منتج بطل اشتريه لان واحد من اصحابي مجربه من سنين وكنت معجب بالمنتج والحمد لله اخذته وياليت اني اخذته بعد العرض لاكن للاسف اخذته قبل العرض باسبوع صراااحه لايفوتكم بتدعون لي',
      date: 'July 16, 2021',
      datetime: '2021-07-16',
      author: 'عميل موثوق',
      avatarSrc:
        'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    },
    {
      id: 2,
      rating: 4,
      content: 'ممتاز وعملي وخفيف ومبتكر ولكن ياليت كان له منفذ type c خارجي يشحنه بشكل سريع',
      date: 'July 12, 2021',
      datetime: '2021-07-12',
      author: 'هشام السليمان',
      avatarSrc:
        'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    },
  ],
  stats: [
    { rating: 5, count: 1019 },
    { rating: 4, count: 162 },
    { rating: 3, count: 97 },
    { rating: 2, count: 199 },
    { rating: 1, count: 147 },
  ],
};

export default function ProductDetails() {
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState<number>(1);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        if (!slug) throw new Error('Invalid product slug');

        const response = await RetailProductsApiEndpoints.getRetailProductBySlug(slug);
        setProduct(response.data);
        setLoading(false);
      } catch (err) {
        console.log(err instanceof Error ? err : new Error('An unknown error occurred'));
      }
    };

    fetchProduct();
  }, [slug]);

  const handleDecrement = (): void => {
    setQuantity((prev) => (typeof prev === 'number' && prev > 1 ? prev - 1 : 1));
  };

  const handleIncrement = (): void => {
    setQuantity((prev) => (typeof prev === 'number' ? prev + 1 : 1));
  };

  return (
    <>
      {loading && <ProductDetailsSkeleton />}
      {!loading && product && (
        <div className='bg-white py-24 sm:py-10'>
          <div className='mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-0'>
            <div className='lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8'>
              <div className='lg:order-2 rtl:lg:order-1'>
                <ProductMedia images={product.attributes.attachments.images} videos={product.attributes.attachments.videos} />
              </div>
              <ProductInfo
                data={{
                  id: product.id,
                  name: product.attributes.name,
                  price: product.attributes.base_price_with_vat,
                  marketingPrice: product.attributes.marketing_price,
                  inStock: product.attributes.in_stock,
                  shortDescription: product.attributes.short_description,
                  description: product.attributes.description,
                  purchasesCount: product.attributes.purchases_count,
                }}
                handleDecrement={handleDecrement}
                handleIncrement={handleIncrement}
                reviews={reviews}
                quantity={quantity}
              />
            </div>
          </div>
          <div className='mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-0 pt-16'>
            <div className='lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8'>
              <div className='order-2 lg:order-2'>
                <TabGroup>
                  <div className='border-b border-gray-200'>
                    <TabList className='-mb-px flex space-x-8 rtl:space-x-reverse'>
                      <Tab className='whitespace-nowrap border-b-2 border-transparent pb-6 text-sm font-medium text-secondary hover:border-secondary data-[selected]:border-primary data-[selected]:text-primary focus:outline-none'>
                        {t('productDetails.customerFeedback')}
                      </Tab>
                      <Tab className='whitespace-nowrap border-b-2 border-transparent pb-6 text-sm font-medium text-secondary hover:border-secondary data-[selected]:border-primary data-[selected]:text-primary focus:outline-none'>
                        {t('productDetails.deliveryAndPayment')}
                      </Tab>
                      <Tab className='whitespace-nowrap border-b-2 border-transparent pb-6 text-sm font-medium text-secondary hover:border-secondary data-[selected]:border-primary data-[selected]:text-primary focus:outline-none'>
                        {t('productDetails.faq')}
                      </Tab>
                    </TabList>
                  </div>
                  <TabPanels as={Fragment}>
                    <TabPanel>
                      <h3 className='sr-only'>Customer Reviews</h3>
                      <ProductReviews reviews={reviews} />
                    </TabPanel>
                    <TabPanel className='text-sm text-secondary'>
                      <h3 className='sr-only'>Delivery & payment</h3>
                      <Delivery />
                    </TabPanel>
                    <TabPanel className='text-sm text-secondary'>
                      <h3 className='sr-only'>FAQs</h3>
                      <FAQs />
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </div>
              <ReviewsStatistics reviews={reviews} setIsReviewModalOpen={setIsReviewModalOpen} />
            </div>
          </div>
        </div>
      )}
      <AddReviewModal isOpen={isReviewModalOpen} setIsOpen={setIsReviewModalOpen} />
    </>
  );
}
