import { RetailProductApiResponse } from '../types/retail-product';
import HttpHelpers from './helpers';

const RetailProductsApiEndpoints = {
  getRetailProducts: (): Promise<RetailProductApiResponse> => HttpHelpers.unAuthenticatedAxios
    .get<RetailProductApiResponse>('/api/v1/retail_products')
    .then((response) => response.data),
  getRetailProductBySlug: (slug: string) => HttpHelpers.unAuthenticatedAxios
    .get(`/api/v1/retail_products/${slug}`)
    .then((response) => response.data),
};

export default RetailProductsApiEndpoints;
