import { useTranslation } from 'react-i18next';
import { TruckIcon, ClockIcon } from '@heroicons/react/24/outline';

export type FulfillmentMethod = 'delivery' | 'pickup' | null;

interface FullfillmentMethodsProps {
  onSelect: (option: FulfillmentMethod) => void;
  selectedOption: 'delivery' | 'pickup' | null;
  isDeliveryAvailable: boolean;
}

function FullfillmentMethods({ onSelect, selectedOption, isDeliveryAvailable }: FullfillmentMethodsProps) {
  const { t } = useTranslation();

  return (
    <div className='border-b border-gray-200 pb-6 mb-4'>
      <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.fulfillmentMethod.choose')}</h2>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
        {isDeliveryAvailable && (
          <button
            type='button'
            onClick={() => onSelect('delivery')}
            className={`flex items-center p-4 rounded-lg border-2 bg-white transition-colors ${selectedOption === 'delivery'
              ? 'border-primary bg-primary/5'
              : 'border-gray-200 hover:border-gray-300'
              }`}
            aria-pressed={selectedOption === 'delivery'}
          >
            <TruckIcon className='h-6 w-6 mr-3 text-gray-600 rtl:mr-0 rtl:ml-3' />
            <div className='text-left rtl:text-right'>
              <div className='font-medium text-black'>{t('checkout.fulfillmentMethod.delivery')}</div>
              <div className='text-sm text-secondary'>{t('checkout.fulfillmentMethod.deliveryDescription')}</div>
            </div>
          </button>
        )}

        <button
          type='button'
          onClick={() => onSelect('pickup')}
          className={`flex items-center p-4 rounded-lg border-2 bg-white transition-colors ${selectedOption === 'pickup'
            ? 'border-primary bg-primary/5'
            : 'border-gray-200 hover:border-gray-300'
            }`}
          aria-pressed={selectedOption === 'pickup'}
        >
          <ClockIcon className='h-6 w-6 mr-3 text-gray-600 rtl:mr-0 rtl:ml-3' />
          <div className='text-left rtl:text-right'>
            <div className='font-medium text-black'>{t('checkout.fulfillmentMethod.pickup')}</div>
            <div className='text-sm text-secondary'>{t('checkout.fulfillmentMethod.pickupDescription')}</div>
          </div>
        </button>
      </div>
    </div>
  );
}

export default FullfillmentMethods;
